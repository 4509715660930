<template>
  <div class="nav-menu-promo-group">
    <template v-if="GET_IS_ABOVE_BREAKPOINT('lg')">
      <template v-for="(item, index) in items">
        <NavigationMenuPromoGroupItemSmall
          :key="index"
          v-if="appearance === 'card-small'"
          :content="item"
        />
        <NavigationMenuPromoGroupItem v-else :key="index" :content="item" />
      </template>
    </template>
    <template v-else>
      <NavigationMenuPromoGroupMobile :items="items" :appearance="appearance" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ContentfulMixin from "@/contentfulComponents/ContentfulMixin"
import ContentfulGQL from "@/integration/ContentfulGQL"

import NavigationMenuPromoGroupItem from "./NavigationMenuPromoGroupItem.vue"
import NavigationMenuPromoGroupItemSmall from "./NavigationMenuPromoGroupItemSmall.vue"
import NavigationMenuPromoGroupMobile from "./NavigationMenuPromoGroupMobile.vue"

import { productTypes } from "../../../../store/constants"

export default {
  props: {
    appearance: String
  },

  mixins: [ContentfulMixin],

  contentfulModel: "Group",

  data: () => ({
    items: []
  }),

  components: {
    NavigationMenuPromoGroupItem,
    NavigationMenuPromoGroupItemSmall,
    NavigationMenuPromoGroupMobile
  },

  methods: {
    // onContentfulData is called from ContentfulMixin
    onContentfulData(content) {
      Promise.all(
        content.itemsCollection.items.map(item => {
          if (Object.values(productTypes).includes(item.__typename)) {
            return Promise.resolve(
              this.GET_PRODUCT_ENTRY(item.sys.id, item.__typename)
            )
          } else {
            return ContentfulGQL.fetchEntryById(item.__typename, item.sys.id)
          }
        })
      ).then(itemEntries => {
        this.items = itemEntries
      })
    }
  },

  computed: {
    ...mapGetters("content", ["GET_PRODUCT_ENTRY"]),
    ...mapGetters("breakpoints", ["GET_IS_ABOVE_BREAKPOINT"])
  }
}
</script>

<style lang="scss"></style>
