<template>
  <div v-if="content" class="nav-promo-item-collection mb-20">
    <LinkTag
      @click.native="handleClick"
      interstitial
      :to="getLink"
      class="nav-promo-item-collection__link"
    >
      <div class="nav-promo-item-collection__layout flex--row-start-center">
        <div class="nav-promo-item-collection__image-holder">
          <bleach-image
            v-if="content.linkImage && content.linkImage[0]"
            class="nav-promo-item-collection__image"
            :src="content.linkImage[0].original_secure_url"
            :sizes="{
              sm: [80, 'ar_1'],
              md: [80, 'ar_1']
            }"
            :default-size="[80, 'ar_1']"
            :alt="content.linkTitle || content.title"
          />
        </div>
        <div
          class="nav-promo-item-collection__text-holder flex--col-center-start"
        >
          <div
            class="nav-promo-item-collection__title text-commerce-tag text--clr-black"
          >
            {{ content.linkTitle || content.title }}
          </div>
          <div
            class="nav-promo-item-collection__cta text-commerce-button-primary-small text--clr-black"
          >
            {{ UI_ELEMENT("nav_menu_ecom_cta") }}
          </div>
        </div>
      </div>
    </LinkTag>
  </div>
</template>

<script>
import GTMAPI from "@/integration/GTMAPI"

export default {
  props: {
    content: Object
  },

  methods: {
    handleClick() {
      GTMAPI.trackEvent("navigation.promo.clicked", {
        item_title: this.content.title
      })
    }
  },

  computed: {
    getLink() {
      return {
        name: this.content.__typename,
        params: { slug: this.fullSlugFromEntry(this.content) }
      }
    }
  }
}
</script>

<style lang="scss">
.nav-promo-item-collection {
  border: 1px solid getcolour(bleach_grey_90);
  width: 100%;
  height: 80px;

  @include media-breakpoint-up(xl) {
    height: 100px;
  }

  &__link,
  &__layout {
    width: 100%;
    height: 100%;
  }

  &__image-holder {
    position: relative;
    flex: 0 0 50px;
    width: 50px;
    height: 100%;
    background-color: getcolour(bleach_grey_90);

    @include media-breakpoint-up(xl) {
      flex: 0 0 80px;
      width: 80px;
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__text-holder {
    flex: 1 0 auto;
    padding-left: 12px;

    @include media-breakpoint-up(xl) {
      padding-left: 16px;
    }
  }
}
</style>
