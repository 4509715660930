<template>
  <div class="nav-promo-group-carousel">
    <BleachSwiper ref="swiper" :options="swiperOptions">
      <BleachSwiperSlide v-for="(item, index) in items" :key="index">
        <NavigationMenuPromoGroupMobileItem
          :content="item"
          :appearance="appearance"
        />
      </BleachSwiperSlide>
    </BleachSwiper>
  </div>
</template>

<script>
import NavigationMenuPromoGroupMobileItem from "./NavigationMenuPromoGroupMobileItem.vue"

import BleachSwiper from "@/components/swiper/BleachSwiper.vue"
import BleachSwiperSlide from "@/components/swiper/BleachSwiperSlide.vue"

export default {
  props: {
    items: Array,
    appearance: String
  },

  components: {
    NavigationMenuPromoGroupMobileItem,
    BleachSwiper,
    BleachSwiperSlide
  },

  data: () => ({
    swiperOptions: {
      slidesPerView: 3.5,
      spaceBetween: 15,
      freeMode: true,
      loop: false,
      centeredSlides: false,
      slidesOffsetBefore: 24,
      slidesOffsetAfter: 24
    },
    swiper: null
  })
}
</script>

<style lang="scss">
.nav-promo-group-carousel {
  margin: 0 (-$page-margin-xs-up);
}
</style>
